import {
  ALOE_100,
  APRICOT_60,
  OwnUpLargeBody,
  OwnUpSmallBody,
  PropsWithTheme,
  SAGE_100
} from '@rategravity/own-up-component-library';
import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { OwnUpLargeBodyMedium } from '../../typography';

const OurCalculationsTextWrapper = styled(OwnUpSmallBody)`
  display: inline-block;
  border-radius: 8px;
  padding: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(1, 1.5)};
  // Bottom margin offset to compensate for built-in padding in OwnUpGridItem
  // This may change
  margin-bottom: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(-0.5)}px;
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.only('xs')} {
    margin-left: ${({ theme }) => theme.spacing(1)}px;
  }
`;
export const OurCalculationsText = ({ children }: PropsWithChildren<{}>) => (
  <OurCalculationsTextWrapper variant="body1">{children}</OurCalculationsTextWrapper>
);

const OurCalculationsOperatorWrapper = styled(OwnUpSmallBody)`
  padding: 0;
  text-align: center;
`;
export const OurCalculationsOperator = ({ children }: PropsWithChildren<{}>) => (
  <OurCalculationsOperatorWrapper variant="body1">{children}</OurCalculationsOperatorWrapper>
);

const AvgInterestAnnotationWrapper = styled(OurCalculationsTextWrapper)`
  background-color: ${APRICOT_60};
`;
export const AvgInterestAnnotation = ({ children }: PropsWithChildren<{}>) => (
  <AvgInterestAnnotationWrapper variant="body1">{children}</AvgInterestAnnotationWrapper>
);

const OuInterestAnnotationWrapper = styled(OurCalculationsTextWrapper)`
  background-color: ${ALOE_100};
  color: white;
`;
export const OuInterestAnnotation = ({ children }: PropsWithChildren<{}>) => (
  <OuInterestAnnotationWrapper variant="body1">{children}</OuInterestAnnotationWrapper>
);

const SavingsAnnotationWrapper = styled(OurCalculationsTextWrapper)`
  background-color: ${SAGE_100};
`;
export const SavingsAnnotation = ({ children }: PropsWithChildren<{}>) => (
  <SavingsAnnotationWrapper variant="body1">{children}</SavingsAnnotationWrapper>
);

export const OurCalculationsNumber = ({ children }: PropsWithChildren<{}>) => (
  <OwnUpLargeBody variant="body1">{children}</OwnUpLargeBody>
);

export const OurCalculationsTitle = ({ children }: PropsWithChildren<{}>) => (
  <OwnUpLargeBodyMedium variant="body1">{children}</OwnUpLargeBodyMedium>
);
