import React from 'react';
import { Ankle } from '../components/ankle';
import { Layout } from '../components/layout';
import { SavingsMethodology } from '../components/savings-methodology';

export const Methodology = () => (
  <Layout>
    <SavingsMethodology />
    <Ankle />
  </Layout>
);

export default Methodology;
